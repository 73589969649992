jQuery(document).ready(function ($) {
  // ============================================= POPUP PROMOTION HIKASHOP
  // $(".hika__popup")
  //   .find("i")
  //   .click(function () {
  //     $(".hika__popup").removeClass("active");
  //   });
  // $(document).on("mouseup", function (e) {
  //   if (!e.target.closest(".hika__popup")) {
  //     $(".hika__popup").removeClass("active");
  //   }
  // });
  // ============================================= EO POPUP PROMOTION HIKASHOP

  // ============================ session storage

  if (!sessionStorage.getItem("Popup"))
    document.querySelector(".hika__popup").classList.add("active");

  function popupPromo() {
    var setBtn = document.querySelector("#popup__btn");
    var setPopup = document.querySelector(".hika__popup");
    let now = new Date();
    let value;

    setBtn.addEventListener("click", function () {
      setPopup.classList.remove("active");

      if (setPopup.classList.contains("active")) {
        value = "active";
      } else {
        value = "disabled";
      }

      // localstorage

      // const item = {
      //   value: value,
      //   // expiry: now.getTime() + 80000,
      // };

      sessionStorage.setItem("Popup", JSON.stringify(value));
    });
  }

  popupPromo();

  var activePop;
  let nowT = new Date();
  let getPopup = JSON.parse(sessionStorage.getItem("Popup"));

  if (!getPopup) {
    return null;
  }

  if (getPopup === "disabled") {
    console.log("hey");
    document.querySelector(".hika__popup").classList.remove("active");
  }
  // if (nowT.getTime() > getPopup.expiry) {
  //   localStorage.removeItem("Popup");
  //   console.log(getPopup.expiry);
  //   return null;
  // }
  // ============================ eo session storage
});
